<template>
    <div class="alert-page">
        <v-row v-if="loading || !alertUser.name" class="sub-cont align-center pt-0 pt-md-3">
            <v-col cols="12" md="10" offset-md="1" v-if="loading">
                <v-skeleton-loader
                    v-for="n in 5"
                    :key="n"
                    :loading="true"
                    type="list-item-three-line"
                    class="mx-auto my-0"
                ></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="10" offset-md="1" v-if="!loading">
                <v-card min-height="60vh">
                    <v-card-text>
                        <v-alert type="error" outlined prominent class="text-center title lh-14">
                            We could not locate the page you're looking for. <br> Please confirm you clicked or pasted the correct URL.
                        </v-alert>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-else class="sub-cont pt-0 pt-md-3 align-center">
            <template v-if="alert.no_show">
                <v-col cols="12" md="10" offset-md="1" v-if="!loading">
                    <v-card min-height="60vh">
                        <v-card-text>
                            <v-alert type="error" outlined prominent class="text-center title">
                                The notes you are trying to view are no longer available.
                            </v-alert>
                        </v-card-text>
                    </v-card>
                </v-col>
            </template>
            <template v-else>
                <v-col cols="12" md="10" offset-md="1" v-if="preview" class="no-print">
                    <v-alert type="info" dense class="mb-0 body-2">This is a preview of your shared notes.</v-alert>
                </v-col>
                <v-col cols="12" md="10" offset-md="1">
                    <v-card class="Dno-br-bot elevation-2 bg-wavelines-secondary-dark" color="secondary" dark>
                        <v-card-text class="px-2 px-md-8 pb-2 pb-md-6 text-center d-flex align-center justify-center">
                            <div style="width: 100%;">
                                <v-row no-gutters class="no-print align-center">
                                    <v-col cols="12" md="3" class="text-center" v-if="alertUser.image">
                                        <a :href="alertUser.image" target="_blank" title="View Photo">
                                            <v-avatar class="action-item elevation-4 mt-0 mt-1 mt-md-2" v-if="alertUser.image" size="130">
                                                <v-img class="img-border-primary elevation-3" :src="alertUser.image" />
                                            </v-avatar>
                                        </a>
                                    </v-col>
                                    <v-col cols="12" :md="(alertUser.image) ? 9 : 12">
                                        <div style="max-width: 630px;" class="hidden-sm-and-down mx-auto">
                                            <h1 class="text-h4 white--text text-center mt-3 mt-md-3 mb-3 shadowed mx-auto lh-23">{{ alertUser.name }} has shared emergency notes with you.</h1>
                                            <p v-if="alert.status == 1" class="mb-0 subtitle-1 text-center shadowed font-weight-medium white--text">
                                                <v-btn class="mx-2 mb-3 mb-sm-0" :href="'tel:' + alertUser.phone_short" color="white" outlined dark text><v-icon small color="primary" class="mr-2">fa fa-phone</v-icon> Call {{ firstName }} {{ alertUser.phone }}</v-btn>
                                                <v-btn class="mx-2" :href="'mailto:' + alertUser.email" target="_blank" color="white" outlined dark text><v-icon small color="primary" class="mr-2">fa fa-envelope</v-icon> Email {{ firstName }}</v-btn>
                                            </p>
                                        </div>
                                        <div class="hidden-md-and-up">
                                            <h1 class="title white--text text-center mt-3 mt-md-3 mb-4 mx-3 lh-15">{{ alertUser.name }} has shared emergency notes with you.</h1>
                                            <p v-if="alert.status == 1" class="mb-0 subtitle-1 text-center shadowed font-weight-medium white--text">
                                                <v-btn class="mx-2 mb-2 mb-sm-0" :href="'tel:' + alertUser.phone_short" color="white" outlined dark text><v-icon small color="primary" class="mr-2">fa fa-phone</v-icon> Call {{ alertUser.phone }}</v-btn>
                                                <v-btn class="mx-2 mb-2" :href="'mailto:' + alertUser.email" target="_blank" color="white" outlined dark text><v-icon small color="primary" class="mr-2">fa fa-envelope</v-icon> Email {{ firstName }}</v-btn>
                                            </p>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="print-only">
                                    <v-col cols="12">
                                        <h1 class="headline black--text text-center mt-0 mb-3 mx-auto">Emergency Notes shared by {{ alertUser.name }}</h1>
                                        <p class="mb-0 subtitle-1 text-center font-weight-medium black--text">
                                            {{ alertUser.phone }} &bull; {{ alertUser.email }}
                                        </p>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-card-text>
                    </v-card>
                    
                    <v-row dense class="mt-1" v-if="isAuthd">
                        <v-col cols="12">
                            <v-card class="elevation-2" color="white">
                                <v-card-text class="secondary--text" :class="(showAuth) ? 'px-1 pb-2 px-md-3 pb-md-3 pt-2' : 'pt-2'">
                                    <h3 class="title text-center"><v-icon color="primary" class="mr-2">fa fa-user-lock</v-icon> In Case of Emergency: <v-btn href="javascript:window.print();" icon absolute right class="float-right hidden-sm-and-down"><v-icon>fa fa-print</v-icon></v-btn></h3>
                                    <v-divider class="mt-2 mb-3" />
                                    <p v-show="!showAuth || isAuthd" class="subtitle-1 dfont-weight-medium text-center secondary--text mt-1 px-3 mb-0 lh-14 mx-auto" style="dmax-width: 600px;">
                                        {{ firstName }} has asked us to share the following information with you.
                                        <template v-if="!isAuthd">
                                            <br>
                                            <v-btn class="mt-3 mb-2" @click.native="showAuth=true" color="primary">View Emergency Information</v-btn>
                                        </template>
                                        <template v-else>
                                            <span class="d-block mt-2 mb-1">Please read carefully as {{ firstName }} may need you to take action or relay this information to others <span class="print-only d-inline"> for assistance</span>.</span>
                                        </template>
                                    </p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <AuthShare v-if="!isAuthd" :firstName="firstName" :user="user" :preview="preview" @authd="handleAuth($event)" />

                    <v-expansion-panels class="mt-3 no-print" v-if="isAuthd" multiple>
                        <template v-for="item in alert.notes">
                            <v-expansion-panel mandatory :key="item.uuid" @click="preview=null">
                                <v-expansion-panel-header class="pl-3" @click="scrollToNote()">
                                    <span class="font-weight-medium secondary--text title"><v-icon color="primary" class="mr-1 alert-note-icon">{{ getIcon(5) }}</v-icon> {{ item.title }}</span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-divider />
                                    <Note :note="item" :name="firstName" class="mt-1 mb-0" />
                                    <v-card class="elevation-2 mb-0" v-if="item.files.length">
                                        <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1"><v-icon color="primary" x-small class="mr-0 d-none">fa fa-paperclip</v-icon> This note has {{ item.files.length }} attachment{{ (item.files.length == 1) ? '' : 's' }}:</v-card-title>
                                        <v-divider />
                                        <v-card-text class="py-1">
                                            <v-list class="pt-0 pb-0">
                                                <template v-for="(file, idx) in item.files">
                                                    <v-list-item :key="file.uuid" :class="(item.files.length==1) ? 'pl-0 delevation-2' : 'pl-0'" @click="preview=idx">
                                                        <v-list-item-avatar size="100" tile class="elevation-3">
                                                            <v-img v-if="file.type == 1" cover class="secondary" max-width="100" max-height="100" :src="file.file" />
                                                            <div v-if="file.type==2" style="width: 100%;" class="grey lighten-3 text-center pt-3 py-md-5">
                                                                <v-icon :size="($vuetify.breakpoint.smAndDown) ? 50 : 80" color="primary" class="mt-1">fa fa-file-pdf</v-icon>
                                                            </div>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title class="secondary--text body-2 text-center text-md-left">{{ file.caption || file.name }}</v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-action class="hidden-sm-and-down">
                                                            <v-btn :href="file.file" small target="_blank" outlined color="primary">View</v-btn>
                                                        </v-list-item-action>
                                                    </v-list-item>
                                                </template>
                                            </v-list>
                                        </v-card-text>
                                        <MediaViewer v-if="preview!==null" @closed="preview=null" :preview="preview" :files="item.files" />
                                    </v-card>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </template>
                    </v-expansion-panels>

                    <div class="print-only" v-if="isAuthd">
                        <template v-for="item in alert.notes">
                            <v-card :key="'print-'+item.uuid" flat class="mt-3 print-break-page">
                                <v-card-title class="py-3">
                                    <span class="font-weight-medium secondary--text title"><v-icon color="black" class="mr-1 float-left alert-note-icon">{{ getIcon(item.type) }}</v-icon> {{ getType(item.type) }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-divider />
                                    <Note :note="item" :name="firstName" class="mt-1 mb-0" />
                                    <v-card class="elevation-2 mb-0" v-if="item.files.length">
                                        <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1"><v-icon color="primary" x-small class="mr-0 d-none">fa fa-paperclip</v-icon> This note has {{ item.files.length }} attachment{{ (item.files.length == 1) ? '' : 's' }}:</v-card-title>
                                        <v-divider />
                                        <v-card-text class="pt-2 pb-1">
                                            <v-row dense>
                                            <template v-for="(file, idx) in item.files">
                                                <v-col cols="12" :key="file.uuid">
                                                    <template v-if="file.type == 1">
                                                        <img :src="file.file" alt="Attached Image" style="max-width: 100%;" class="mx-auto" />
                                                        <p class="body-2 text-center black--text mt-1 mb-1">{{ file.caption || 'No caption provided for this image.' }}</p>
                                                    </template>
                                                    <template v-else>
                                                        <p class="body-2 lh-14 mb-1 black--text">
                                                            <b>Document: {{ file.name }}</b><br>
                                                            {{ file.file }}<br>
                                                            {{ file.caption }}
                                                        </p>
                                                    </template>
                                                    <v-divider v-if="idx < item.files.length-1" />
                                                </v-col>
                                            </template>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-card-text>
                            </v-card>
                        </template>
                    </div>

                    <p class="text-center mt-4 no-print" v-if="isAuthd"><v-btn text href="javascript:window.print();" outlined><v-icon class="pr-2">fa fa-print</v-icon> Print this page</v-btn></p>

                    <v-alert class="mt-3 body-2 no-print" type="info" dense>This page expires on {{ $moment.utc(alert.created_at).add(alert.expire_days, 'days').tz(alertUser.timezone).format('MMMM Do YYYY') }}.</v-alert>
                </v-col>
            </template>
        </v-row>
    </div>
</template>
<script>

import { mapState } from 'vuex'
import tzs from '@/data/timezones'

export default {
    name: 'Share',
    metaInfo() {
        return { 
            meta: [{
                name: 'robots', content: 'noindex'
            }]
        }
    },
    components: {
        AuthShare: () => import('@/components/alert/AuthShare'),
        Note: () => import('@/components/alert/Note'),
        MediaViewer: () => import('@/components/common/MediaViewer'),
    },
    computed: {
        ...mapState(['user']),
        firstName(){
            let parts = this.alertUser.name.split(' ');
            return parts[0] || this.alertUser.name;
        }
    },
    data: () => ({
        accessCode: '',
        alert: {},
        contact: {},
        errors: null,
        isAuthd: false,
        loading: true,
        preview: null,
        showAuth: false,
        showGeo: false,
        thisTimezone: '',
        timezones: tzs.tzs,
        alertUser: {},
        userKey: 0,
    }),
    methods: {
        initData(){
            this.getAlert();
        },
        getAlert(){ 
            let shareId = this.$route.params.id;
            if(location.href.indexOf('preview') !== -1 && this.user.uuid && !this.preview){
                this.handlePreview();
                return;
            }
            let request = {
                endpoint: '/notes/share/' + shareId, 
                method: 'get',
                apiKey: this.alertUser.api_token || null
            };
            if(this.preview){
                request.endpoint += '?user=' + this.user.uuid;
            }
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.alertUser = res.data.user;
                    this.alert = res.data;
                    this.isAuthd = !res.data.reauth;
                    this.$appFn.setTitle("Shared Notes from " + this.alertUser.name + " - I'm Okay", true);
                    this.getTimezone();
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.loading = false;
            });
        },
        scrollToNote(){
            setTimeout(() => {
                if(document.querySelector('.v-expansion-panel-header--active')){
                    this.$vuetify.goTo('.v-expansion-panel-header--active', {
                        container: 'body',
                        offset: 10
                    });
                }else{
                    this.$vuetify.goTo('.v-expansion-panels', {
                        container: 'body',
                        offset: 20
                    });
                }
            }, 100);
        },
        handlePreview(){
            this.preview = true;
            this.alertUser = this.user;
            this.getAlert();
        },
        handleAuth(data){
            if(this.preview){
                this.isAuthd = true;
                return;
            }
            this.alert = data;
            this.isAuthd = true;
            ++this.userKey;
        },
        getTimezone(){
            for(let i in this.timezones){
                if(this.timezones[i].value == this.alertUser.timezone){
                    this.thisTimezone = this.timezones[i].abbr; 
                }
            }
        },
        getIcon(itemType){
            if(itemType == 1){
                return 'fa fa-notes-medical';
            }else if(itemType == 3){
                return 'fa fa-dog';
            }else if(itemType == 2){
                return 'fa fa-users';
            }else if(itemType == 4){
                return 'fa fa-home';
            }

            return 'fa fa-comment-medical';
        },
        getType(itemType){
            if(itemType == 1){
                return 'Medical Notes';
            }else if(itemType == 3){
                return 'Pet Care Notes';
            }else if(itemType == 2){
                return 'Child/Family Care Notes';
            }else if(itemType == 4){
                return 'Property Notes';
            }else if(itemType == 5){
                return 'Other Emergency Notes';
            }
        },
        getNoteDesc(idx){
            if(idx == 1){
                return 'May include medication allergies or medical conditions ' + this.firstName + ' may have.';
            }else if(idx == 2){
                return 'Actions that may be needed for those left in their care.';
            }else if(idx == 3){
                return 'Instructions for feeding and caring for their pets.';
            }else if(idx == 4){
                return 'Notes for accessing and caring for their home and plants.';
            }

            return 'Notes that ' + this.firstName + ' asked us to share with you.';
        },
    },
    mounted(){
		this.initData();
	},
    watch: {

    }
}
</script>